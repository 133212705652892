import styled from 'styled-components';
import Toggle from './Toggle';
import RadioGroup from './RadioGroup';
import { FieldGroupWrapper, LabelWrapper } from './FormStyles'

interface IProps {
  isMarried: boolean;
  hasSpouseAA: boolean;
  countChildren: number;
  countAdultChildren: number;
  countParents: number;
  handleChange: Function;
};

export default function Family({ isMarried, hasSpouseAA, countChildren, countAdultChildren, countParents, handleChange }: IProps): JSX.Element {
  const handleMarriedChange = (isMarried: boolean): void => {
    if (handleChange) handleChange((prev: any) => {
      const hasSpouseAA = !isMarried ? false : prev.hasSpouseAA;
      return { ...prev, isMarried, hasSpouseAA }
    });
  };
  
  const handleSpouseAAChange = (hasSpouseAA: boolean): void => {
    if (handleChange) handleChange((prev: any) => {
      const isMarried = hasSpouseAA ? true : prev.isMarried;
      return { ...prev, hasSpouseAA, isMarried }
    });
  };

  const handleChildrenChange = (count: string): void => {
    const countChildren = parseInt(count);
    if (handleChange) handleChange((prev: any) => ({ ...prev, countChildren }));
  };

  const handleAdultChildrenChange = (count: string): void => {
    const countAdultChildren = parseInt(count);
    if (handleChange) handleChange((prev: any) => ({ ...prev, countAdultChildren }));
  };

  const handleParentsChange = (count: string): void => {
    const countParents = parseInt(count);
    if (handleChange) handleChange((prev: any) => ({ ...prev, countParents }));
  };

  // useEffect(() => { if (!isMarried) setHasSpouseAA(false); }, [isMarried]);

  // useEffect(() => { if (hasSpouseAA) setIsMarried(true); }, [hasSpouseAA]);
  
  const childrenList = Array.from({ length: 10 }, (x, i) => i.toString());

  return (
    <>
        <FieldGroupWrapper>
          <LabelWrapper hideLabel>Marital Status</LabelWrapper>
            <MaritalStatusWrapper>
            <div>
              <Toggle
                label="Married"
                on={isMarried}
                onToggle={handleMarriedChange}
              />
            </div>
            <div>
              <Toggle
                label="Spouse A/A"
                on={hasSpouseAA}
                onToggle={handleSpouseAAChange}
              />
            </div>
          </MaritalStatusWrapper>
        </FieldGroupWrapper>
        <div>
          <RadioGroup
            legend="Children Under 18"
            items={childrenList}
            value={countChildren}
            onChange={handleChildrenChange}
          />
        </div>
        <div>
          <RadioGroup
            legend="Children Over 18"
            items={childrenList}
            value={countAdultChildren}
            onChange={handleAdultChildrenChange}
          />
        </div>
        <div>
          <RadioGroup
            legend="Parent Dependents"
            items={['0', '1', '2']}
            value={countParents}
            onChange={handleParentsChange}
          />
        </div>
    </>
  )
}

const MaritalStatusWrapper = styled.div`
  display: flex;
  grid-gap: 1rem;
`;
