import styled, { css } from 'styled-components'

export const FieldGroupWrapper = styled.div`
  margin-bottom: 1rem;
`;

export const LabelWrapper = styled.span<{ hideLabel?: boolean }>`
  ${({ hideLabel }) => hideLabel ? css`
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  `: css`
    display: block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0.5rem;  
  `}
`;
