import React from 'react'
import styled, { css } from 'styled-components'
import { FieldGroupWrapper } from './FormStyles'

interface IRadioGroup {
  legend: string;
  items: Array<string | number | IThing>;
  name?: string;
  hideLegend?: boolean;
  value?: string | number;
  onChange?: Function;
}

interface IThing {
  label: string;
  value: string | number;
}

export default function RadioGroup({ legend, name, onChange, hideLegend = false, items, value = '' }: IRadioGroup): JSX.Element {
  const slugify = (value: string | number): string => value.toString().toLowerCase().replace(/\s/g, '-');
  
  const groupName = name || slugify(legend);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (onChange) onChange(value);
  };

  return (
    <Wrapper>
      <fieldset>
          <legend>{legend}</legend>
          <ItemsWrapper>
            {items.map((item: string | number | IThing) => {
              const label = typeof item === 'object' ? item.label : item;
              const itemValue = typeof item === 'string' || typeof item === 'number' ? item : item.value;
              // const value = label; //typeof label === 'number' ? label.toString() : label;
              
              const id = `${groupName}-${slugify(label)}`;
              const isItemChecked = value.toString() === itemValue;
              return (
                <ItemWrapper key={id} checked={isItemChecked}>
                  <input type="radio" id={id} value={itemValue} checked={isItemChecked} name={groupName} onChange={handleChange} />
                  <label htmlFor={id}>{label}</label>
                </ItemWrapper>
              );
            })}
          </ItemsWrapper>
      </fieldset>
    </Wrapper>
  )
}

const Wrapper = styled(FieldGroupWrapper)`
  fieldset {
    border: 0;
    min-width: 0;
    padding: 0;
  }
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
`
const ItemWrapper = styled.div<{ checked: boolean }>`
  background-color: ${({ checked }) => checked ? `var(--color-secondary)` : `var(--color-button)`};
  ${({ checked }) => checked && css`color: #fff`};
  flex-shrink: 0;
  overflow: hidden;

  &:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  &:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
    
  input { display: none; }

  label {
    cursor: pointer;
    display: flex;
    font-size: 3rem;
    line-height: 1;
    height: 2em;
    width: 2em;
    min-width: 2em;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;