import { useState } from 'react'
import styled from 'styled-components';
import { IDisability } from '../interfaces';
import Button from './Button';
import Pill from './Pill';
import RadioGroup from './RadioGroup';
import { FieldGroupWrapper } from './FormStyles'

interface IProps {
  handleChange: Function;
  selections: IDisability[];
};

export default function Disabilities({ selections, handleChange }: IProps): JSX.Element {
  const [extremitySelection, setExtremitySelection ] = useState('');

  const handleAddDisability = (value: string): void => {
    const amount = parseInt(value);
    const newDisability: IDisability = { value: amount / 100 };
    if(extremitySelection !== '') newDisability.label = extremitySelection;
    handleChange([...selections, newDisability]);
    setExtremitySelection('');
  };

  const handleRemoveDisability = (i: number) => {
    const modifiedDisabilities = [...selections];
    modifiedDisabilities.splice(i, 1);
    handleChange([...modifiedDisabilities]);
  };

  const disabilitiesList = Array.from({ length: 10 }, (x, i) =>
    ((i + 1) * 10).toString()
  );

  return (
    <>
      <RadioGroup
          legend="Extremities"
          items={[
            { label: 'Arm (L)', value: 'Left Arm' },
            { label: 'Leg (L)', value: 'Left Leg' },
            { label: 'Leg (R)', value: 'Right Leg' },
            { label: 'Arm (R)', value: 'Right Arm' },
          ]}
          onChange={setExtremitySelection}
          value={extremitySelection}
        />
        <FieldGroupWrapper>
          <fieldset>
            <legend>Disability Rating</legend>
            {disabilitiesList.map((x) => (
              <Button key={x} handleClick={handleAddDisability} value={x}>
                {x}%
              </Button>
            ))}
          </fieldset>
        </FieldGroupWrapper>
        <DisabilitiesWrapper>
          {selections.length ? (
            selections.map(({ label, value }: IDisability, idx: number) => {
              const text = `${label ? `${label} ` : ''}${value * 100}%`;
              const handleClick = () => handleRemoveDisability(idx);
              return <Pill key={idx} handleRemove={handleClick} text={text} size={'lg'} />
            })
          ) : ("No Disabilities Selected")}
        </DisabilitiesWrapper>
    </>
  )
}

const DisabilitiesWrapper = styled(FieldGroupWrapper)`
  display: flex;
  grid-gap: 1rem;
  flex-wrap: wrap;
`;