import React from 'react'
import styled from 'styled-components';

interface IButton {
  children?: JSX.Element | JSX.Element[] | string | string[];
  handleClick?: Function;
  disabled?: boolean;
  value?: string | number;
  filled?: boolean;
}
export default function Button({ children, handleClick, disabled = false, value, filled }: IButton): JSX.Element {
  const handleClickEvent = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const el = e.currentTarget;
    const selectedValue = el.value;
    if (handleClick) handleClick(selectedValue);
  }
  return (
    <ButtonWrapper type="button" disabled={disabled} onClick={handleClickEvent} value={value} filled={filled}>{children}</ButtonWrapper>
  )
}

const ButtonWrapper = styled.button<{ filled?: boolean }>`
  background-color: ${({ filled }) => filled ? `var(--color-secondary)` : `var(--color-button)`};
  color: ${({ filled }) => filled ? `#fff` : `var(--color-text)`};
  cursor: pointer;
  border: 0;
  border-radius: 0.5rem;
  font-size: 2rem;
  line-height: 1;
  padding: 0.5em 0.5em;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.25;
    pointer-events: none;
  }
`;