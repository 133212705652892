import React from 'react'
import styled, { css } from 'styled-components';
import { ReactComponent as IconRemove } from './icons/remove.svg';

interface IPill {
  text: string;
  handleRemove?: Function;
  size?: 'sm' | 'lg';
}
export default function Button({ text, handleRemove, size }: IPill): JSX.Element {
  const handleClickEvent = (e: React.MouseEvent<HTMLButtonElement>): void => {
    const { value } = e.currentTarget;
    if (handleRemove) handleRemove(value);
  }
  return (
    <ButtonWrapper type="button" onClick={handleClickEvent} clickable={!!(handleRemove)} size={size}>
      {text}
      {handleRemove ? <IconRemove /> : null}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.button<{ clickable?: boolean, size?: string }>`
  background-color: var(--color-secondary);
  color: #fff;
  cursor: ${({ clickable }) => clickable ? 'pointer' : 'default'};
  border: 0;
  border-radius: 2em;
  display: flex;
  max-width: 100%;
  grid-gap: 0.25em;
  font-size: 1.4rem;
  ${({ size }) => size === 'lg' && css`font-size: 2rem;`}
  line-height: 1;
  padding: 0.5em;
  vertical-align: middle;
  align-items: center;

  svg, img {
    flex-shrink: 0;
    height: 1em;
    width: 1em;
  }

  svg {
    fill: currentColor;
    opacity: 0.5;
  }
`;