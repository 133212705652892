export function formatCurrency(num: number): string {
  return `$${num.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function formatPercentage(num: number, roundUp?: boolean): string {
  if (roundUp) return `${Math.ceil(num * 100)}%`;
  return `${Math.floor(num * 100)}%`;
}

export function camalize(str: string | undefined): string {
  if(!str) return '';
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
}
