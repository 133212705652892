import { useState, useEffect } from 'react';
import styled from 'styled-components';
import './App.css';
import Logo from './components/Logo';
import Family from './components/Family';
import Disabilities from './components/Disabilities';
import Results from './components/Results';
import Button from './components/Button';
import { IDisability } from './interfaces';

import { camalize } from './components/utilities/functions';

function App() {
  const api = 'https://us-central1-va-disability-calculator-13534.cloudfunctions.net/app';
  const defaultValues = {
    disabilities: [],
    family: { isMarried: false, hasSpouseAA: false, countChildren: 0, countAdultChildren: 0, countParents: 0 },
    rating: { rounded: 0, combined: 0, bilateralFactor: 0 },
    payment: 0,
    extremitySelection: '',
  };
  const [disabilities, setDisabilities] = useState<IDisability[]>(defaultValues.disabilities);
  const [family, setFamily] = useState(defaultValues.family);
  const [rating, setRating] = useState(defaultValues.rating);
  const [payment, setPayment] = useState(defaultValues.payment);
  const [isResetDisabled, setIsResetDisabled] = useState(false);
  
  const handleReset = (): void => {
    setDisabilities(defaultValues.disabilities);
    setFamily(defaultValues.family);
  }

  // Update Payment
  useEffect(() => {
    if (!rating || rating.rounded <= 0) {
      setPayment(0);
      return;
    }
    const endpoint = `/payment/${rating.rounded * 100}`;
    const paramItems = [];
    if (family.isMarried) paramItems.push('isMarried=true');
    if (family.hasSpouseAA) paramItems.push('spouseAid=true');
    if (family.countChildren) paramItems.push(`children=${family.countChildren}`);
    if (family.countAdultChildren) paramItems.push(`adultChildren=${family.countAdultChildren}`);
    if (family.countParents) paramItems.push(`parents=${family.countParents}`);
    const params = paramItems.length ? `?${paramItems.join('&')}` : '';
    const url = `${api}${endpoint}${params}`;
    fetch(url)
      .then(response => response.json())
      .then(({ value }) => setPayment(value));
  }, [family, rating]);

  // Update Rating
  useEffect(() => {
    if (disabilities.length <= 0){
      setRating({ rounded: 0, combined: 0, bilateralFactor: 0 });
      return;
    }
    const endpoint = '/rating';
    const items = disabilities.filter(item => !('label' in item)).map(({ value }) => `items[]=${value * 100}`).join('&');
    const limbs = disabilities.filter(item => 'label' in item).map(({ label, value }) => `${camalize(label)}[]=${value * 100}`).join('&');
    const params = `?${items}&${limbs}`;
    const url = `${api}${endpoint}${params}`;
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        const { total: combined, rounded, bilateral_factor } = data;
        const bilateralFactor = bilateral_factor ? bilateral_factor : 0;
        setRating({ rounded, combined, bilateralFactor })
      });
  }, [disabilities]);

  useEffect(() => {
    setIsResetDisabled(
      disabilities.length === 0
      && family.countChildren === 0
      && family.countAdultChildren === 0
      && family.countParents === 0
      && family.isMarried === false
      && family.hasSpouseAA === false
    );
  }, [disabilities, family]);

  return (
    <Wrapper>
      <div>
        <Logo />
        <Disabilities handleChange={setDisabilities} selections={disabilities} />
        <Family handleChange={setFamily} {...family} />
        <Button handleClick={handleReset} disabled={isResetDisabled}>Reset</Button>
      </div>
      <Results rating={rating} payment={payment} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 420px;
  overflow: hidden;
  padding: 0 1.5rem;
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  /* min-height: 100vh; */
`;

export default App;
