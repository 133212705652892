import { useEffect, useRef } from 'react';
import styled from 'styled-components'
import gsap from 'gsap';
import { formatCurrency, formatPercentage } from './utilities/functions';

interface IResults {
  payment: number;
  rating: {
    rounded: number;
    combined: number;
    bilateralFactor?: number;
  }
}
export default function Results({ payment, rating }: IResults): JSX.Element {
  const paymentEl = useRef<HTMLDivElement>(null);
  const ratingEl = useRef<HTMLDivElement>(null);
  const prevPaymentRef = useRef(payment);
  const prevRatingRef = useRef(rating.rounded);

  useEffect(() => {
    // Animate from previous payment to new payment
    const foo = { value: prevRatingRef.current };
    gsap.to(foo, {
      value: rating.rounded,
      onUpdate: () => {
        ratingEl.current!.innerText = formatPercentage(foo.value, true);
      },
    });
    prevRatingRef.current = rating.rounded;
  }, [rating, ratingEl]);

  useEffect(() => {
    // Animate from previous payment to new payment
    const foo = { amount: prevPaymentRef.current };
    gsap.to(foo, {
      amount: payment,
      onUpdate: () => {
        paymentEl.current!.innerText = formatCurrency(foo.amount);
      },
    });
    prevPaymentRef.current = payment;
  }, [payment, paymentEl]);

  return (
    <ResultsWrapper>
        <div>
          <RatingWrapper ref={ratingEl}>{formatPercentage(rating.rounded, true)}</RatingWrapper>
          {rating.combined !== rating.rounded ? <div>Combined: {formatPercentage(rating.combined, true)}</div> : null}
          {rating.bilateralFactor ? <div>Bilateral Factor: {(rating.bilateralFactor * 100).toFixed(2)}</div> : null}
        </div>
        <PaymentWrapper ref={paymentEl}>{formatCurrency(payment)}</PaymentWrapper>
    </ResultsWrapper>
  )
}


const ResultsWrapper = styled.div`
  display: flex;
  > * { flex : 1; }
`;

const RatingWrapper = styled.div`
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
`;

const PaymentWrapper = styled.div`
  color: var(--color-success);
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;
`