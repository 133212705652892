import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as LogoLight } from './icons/logo.svg';
import { ReactComponent as LogoDark } from './icons/logo-dark.svg';

export default function Logo() {
  const initialMode = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  const [mode, setMode] = useState(initialMode);
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setMode(e.matches ? 'dark' : 'light'));

  return (
    <LogoWrapper>{mode === 'dark' ? <LogoDark /> : <LogoLight />}</LogoWrapper>
  )
}

const LogoWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;

  svg {
    fill: currentColor;
    opacity: 0.25;
    height: 3rem;
    width: 100%;
  }
`;