import React from 'react'
import styled from 'styled-components';

interface IToggle {
  label: string;
  on?: boolean;
  onToggle?: Function;
  hideLabel?: boolean;
}


export default function Toggle({ label, on = false, onToggle, hideLabel = false }: IToggle): JSX.Element {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.checked;
    if (onToggle) onToggle(value);
  }

  return (
    <Label>
      <input type="checkbox" checked={on} onChange={handleChange} />
      <span>{label}</span>
    </Label>
  )
}

const Label = styled.label`
  cursor: pointer;
  display: flex;
  grid-gap: 0.5rem;

  input {
    --size: 1em;
    --gap: 0.3rem;
    --width: calc((var(--size) + var(--gap)) * 2);

    display:inline-block;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    width: var(--width);
    height: calc(var(--size) + (var(--gap) * 2));
    background-color: var(--color-button);
    position: relative;
    border-radius: calc(var(--size) + (var(--gap) * 2) / 2);
    transition: all .1s ease-in-out;
    
    &:after{      
      content:'';
      display:inline-block;
      position:absolute;
      width: var(--size);
      height: var(--size);
      background-color: var(--color-text);
      top: var(--gap);
      left: var(--gap);
      border-radius: 50%;
      transition: all .1s ease-in-out;
    }

    &:checked {
      background-color: var(--color-secondary);

      &:after{
        left: calc(var(--width) - var(--size) - var(--gap));
      }
    }
  }
`;
